<template>
  <b-tabs align="left" content-class="pt-1">
    <b-tab active :title="$t('accountSettingsPage.generalSettings')">
      <AccountSettingGeneral v-if="generalSettings" :settings="generalSettings" />
    </b-tab>
    <b-tab :title="$t('accountSettingsPage.changePassword')">
      <AccountSettingPassword />
    </b-tab>
    <b-tab :title="$t('accountSettingsPage.contactSettings')">
      <AccountSettingNotification v-if="contactSettings" :settings="contactSettings" />
    </b-tab>
  </b-tabs>
</template>

<script>
import AccountSettingGeneral from "@/components/account/AccountSettingGeneral.vue";
import AccountSettingPassword from "@/components/account/AccountSettingPassword.vue";
import AccountSettingNotification from "@/components/account/AccountSettingNotification.vue";
import actions from "@/store/account/actions.js";

export default {
  name: "AccountSettings",

  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingNotification,
  },

  data() {
    return {
      generalSettings: null,
      contactSettings: null,
    };
  },

  async beforeMount() {
    const accountDetails = await this.$store.dispatch(actions.GET_ACCOUNT_DETAILS);

    this.generalSettings = {
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      email: accountDetails.email,
      countryIsoCode: accountDetails.countryIsoCode,
      countryDialCode: accountDetails.countryDialCode,
      phoneNumber: accountDetails.phoneNumber,
      about: accountDetails.about,
      profilePicture: accountDetails.profilePicture,
      spokenLanguageIds: accountDetails.spokenLanguageIds,
    };

    this.contactSettings = {
      hideEmail: accountDetails.hideEmail,
      hidePhoneNumber: accountDetails.hidePhoneNumber,
      disableWhatsAppNotifications: accountDetails.disableWhatsAppNotifications,
    };
  },

  metaInfo() {
    return {
      title: this.$t("accountSettingsPage.tabTitle"),
    };
  },
};
</script>
